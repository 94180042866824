<template>
  <div v-if="isLink">
    <span
      v-for="(authorRoute, index) in authorRoutes"
      :key="`${authorRoute.params.authorSlug}_${index}`"
    >
      <NuxtLink
        v-if="!isAuthorExcluded(authorRoute.params.author)"
        :to="authorRoute"
        class="author-link"
        >{{ authorRoute.params.author
        }}{{ authorRoutes[index + 1] ? ', ' : '' }}</NuxtLink
      >
      <span v-else class="author-text">
        {{ authorRoute.params.author }}{{ authorRoutes[index + 1] ? ', ' : '' }}
      </span>
    </span>
  </div>
</template>

<script>
import publicationUtils from '@/services/utils/publicationUtils';

export default {
  name: 'AuthorLinkControl',
  props: {
    publication: {
      type: Object,
      required: true
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    authorRoutes() {
      return publicationUtils.getAuthorsRoutesParams(this.publication);
    }
  },
  methods: {
    isAuthorExcluded(author) {
      const excludedAuthors = ['unknown', 'anonymous', 'various'];
      return !author || excludedAuthors.includes(author.toLowerCase());
    }
  }
};
</script>
